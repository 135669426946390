<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div
      class="flex"
      :class="[
        section.styles.textAlignment.value,
      ]"
    >
      <div
        class="overflow-hidden bg-(fg-muted)"
        :class="[
          section.styles.imageSize.value,
          section.styles.imageCrop.value,
          section.styles.cornerRadius.value,
        ]"
      >
        <img
          v-if="section.data?.value[section.lang.value]?.source"
          :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-1920,c-at_max/' : ''}${section.data?.value[section.lang.value]?.source}`"
          class="h-full object-cover"
          :alt="eventTitle"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);
const eventTitle = inject('eventTitle');
</script>
